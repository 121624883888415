
root {
  flex-grow: 1
}

body {
  font-family: 'Poppins', 'Arial, sans-serif';
  font-size: 16px;
}

button {
  text-transform: none;
}

/* Colors */
.primary-color {
  color: #06243d; /* Material-UI blue color */
}

.secondary-color {
  color: #ed7905; /* Material-UI pink color */
}

/* Background */
.default-background {
  background-color: #f5f5f5; /* Light grey background */
}

.ag-grid-size {
  margin-top: 24px;
    margin-left: 10%;
    margin-right: 10%;
}

#selectAll {
    margin-right: 6px !important;
}

.checkbox-container input {
    margin-right: 6px !important;
}

h1 {
    font-size: 36px;
    color: black;
    text-align: center;
    margin-bottom: 0px;
    font-family: 'Poppins', 'Arial', sans-serif;
  }
  
h2 {
    font-size: 18px;
    color: rgba(20, 22, 20, 0.7);
    text-align: center;
    margin-bottom: 40px;
    font-family: 'Poppins', 'Arial', sans-serif;
  }
h3, h4, h5, h6 {
    font-family: 'Poppins', 'Arial', sans-serif;
    
}  
.center-with-image {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    margin-top: 5%;
}
  
.center {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-image: url("../../../public/resources/front_end_background.jpeg");
    background-size: cover;
}
.center-without-bg-image {
  padding-left: 4px;
  padding-right: 4px;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100%;
  background-size: cover;
}

.home-bg {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
  padding-top: 15%;
  background-image: url("../../../public/resources/nix_connect_home_01_1280x640.jpg");
  background-size: cover;
}

.home-bg-container {
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  width: 100%;
  margin-top: 5%;
  padding: 5%;
  background-color:#fafafa;
  background-size: cover;
}

.center::before {
    content: "";
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.25); /* 65% black overlay */
    z-index: -1; /* Place the overlay behind the content */
  }
  

  /* Login form styles */
  .login-form {
    background-color: #FAFAFAFF;
    padding: 30px;
    border-radius: 6px;
    box-shadow: 0 0 5px rgba(110, 110, 110, 0.5);
    max-width: 420px;
    width: 100%;
    height: auto;
  }

  .login-form-without-bg-color {
    background-color: #FAFAFA00;
    padding: 30px;
    border-radius: 6px;
    box-shadow: 0 0 5px rgba(110, 110, 110, 0.5);
    max-width: 460px;
    width: 100%;
  }



  .login-form-h1-title {
    padding-top: 4px;
  }
  
  .login-form-h2-title {
    padding-bottom: 4px;
  }
  
  .form-group {
    margin-bottom: 12px;
  }
  
  .form-group-box {
    margin-bottom: 12px;
    margin-left: 0px;
  }

  .form-group label {
    display: block;
    font-weight: normal;
    color: black; /* Change the text color to black */
    font-size: 16px;
  }
  
  .form-group input[type="email"],
  .form-group input[type="password"] {
    width: 100%;
    padding: 15px;
    font-size: 18px;
    border: 1px solid #ffffff;
    border-radius: 4px;
  }
  
/* Logo styles */
.logo-container {
    display: flex;
    justify-content: center;
    margin: 10px 0; /* Adjust margin as needed */
  }

.company-logo {
    width: 120px; /* Adjust the logo size as needed */
    height: 120px; /* Use the same value to create a circular shape */
    border-radius: 50%; /* Make the image circular */
    object-fit:fill; /* Maintain aspect ratio while filling the container */
    border: 2px solid #E85B07; /* Add a border to the logo if desired */
}

.company-logo-react {
  width: 120px; /* Adjust the logo size as needed */
  height: 120px; /* Use the same value to create a circular shape */
  border-radius: 2%; /* Make the image circular */
  object-fit:fill; /* Maintain aspect ratio while filling the container */
  border: 2px solid #E85B07; /* Add a border to the logo if desired */
}

.btn-sign-in {
    display: block;
    width: 100%;
    font-size: 18px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    background: #ff5b00;
    padding: 10px;
    margin-top: 20px;
    border-radius: 4px;
    cursor: pointer;
    transition: background-color 0.3s ease; 
}

.btn-primary {
    display: inline-flexbox;
    width: 30%;
    height: 48px;
    font-size: 18px;
    font-weight: bold;
    color: green;
    background: none;
    padding: 10px;
    margin-top: 20px;
    border-radius: 4px;
    border-width: 1px;
    cursor: pointer;
    transition: background-color 0.3s ease; 
}
/* Add hover effect */
.btn-sign-in:hover {
    background-color: #000000; /* Change the background color on hover */
    color: white;
} 

.btn-primary:hover {
    background-color: #ffffff; /* Change the background color on hover */
    color: #0051fe;
} 

.button-container {
  display: flex;
  align-items: center; /* Align items vertically */
}

/* Add margin between the buttons */
.button-container button {
  margin-right: 10px; /* Adjust the margin as needed */
}

.btn-clear {
    display: inline-flexbox;
    width: 30%;
    height: 48px;
    font-size: 18px;
    font-weight: bold;
    color: rgb(0, 0, 0);
    background: none;
    padding: 10px;
    margin-top: 20px;
    border-radius: 4px;
    border-width: 1px;
    cursor: pointer;
    transition: background-color 0.3s ease; 
}
.btn-clear:hover {
    background-color: #ffffff; /* Change the background color on hover */
    color: #ff0000;
} 

 /* Login form styles */
 .invite-user-form {
    position: relative;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 100vh;
    width: 100%;
    background-image: url("../../../public/resources/front_end_background.jpeg");
    background-size: cover;
    background-color: #fafafa;
  }

  .invite-user-form-h1-title {
    padding-top: 5px;
  }
  
  .invite-user-form-h2-title {
    padding-bottom: 5px;
  }

/* Unvisited link */
a {
  color: rgb(8, 8, 8); /* Link color */
  text-decoration: none; /* Remove underline by default */
}  
/* Visited link */
a:visited {
  color: #006dfc; /* Color for visited links */
}
/* Mouse over link */
a:hover {
  text-decoration: underline; /* Underline on hover */
}
/* Selected link */
a:active {
  color: #FF3300; /* Color for active (clicked) links */
}

.hyper-link-left-align {
  display: inline-flexbox;
  width: 100%; /* Adjust the width as needed */
  text-align: left; /* Align text to the right within the box */
  padding: 5px; /* Optional padding for content inside the box */
}

.hyper-link-right-align {
  display: inline-flexbox;
  width: 100%; /* Adjust the width as needed */
  text-align: right; /* Align text to the right within the box */
  padding: 5px; /* Optional padding for content inside the box */
}

/* Login form styles */
.reset-password-form {
  background-color: #FAFAFAFF;
  padding: 30px;
  border-radius: 6px;
  box-shadow: 0 0 5px rgba(110, 110, 110, 0.5);
  max-width: 460px;
  width: 100%;
}


.reset-password-h1-title {
  padding-top: 4px;
}

.reset-password-h2-title {
  padding-bottom: 4px;
}

.grid-container {
  height: 100vh;
  width: 100%;
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 112px;
  overflow: hidden;
  background-color: #FFFFFF;
  border-radius: 6px;
}

.margin-small-screen {
  margin-top: 112px;
}

.margin-large-screen {
  margin-top: 136px;
}

